/*-------------------CONTACTO TOP----------------------*/

.bg-top-contacto {
  width: 100%;
  background-image: url(../../src/images/contacto/bg-top.webp);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.bg-top-contacto:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.651);
}

.container-contact {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 45px 0;
}

.form-container {
  width: 50%;
  padding: 15px;
}

.contact-name {
  margin: 10px 0;
}
.contact-name p,
.contact-asunto-email p,
.contact-mensaje p {
  margin-bottom: 5px;
  color: rgb(2, 45, 85);
  font-style: italic;
}
.contact-name input {
  width: 100%;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.021);
  background-color: rgba(143, 143, 143, 0.096);
  border-radius: 5px;
  box-shadow: 1px 3px 4px #00000029;
}

.container-duo {
  display: flex;
  justify-content: space-between;
}

.contact-asunto-email {
  width: 47%;
}

.contact-asunto-email input {
  width: 100%;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.021);
  background-color: rgba(143, 143, 143, 0.096);
  border-radius: 5px;
  box-shadow: 1px 3px 4px #00000029;
}

.contact-mensaje {
  margin: 10px 0;
}

.contact-mensaje textarea {
  width: 100%;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.021);
  background-color: rgba(143, 143, 143, 0.096);
  border-radius: 5px;
  box-shadow: 1px 3px 4px #00000029;
  padding: 10px;
}

.button-submit {
  width: 100%;
  padding: 8px 10px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: rgba(7, 72, 133, 0.658);
  transition: 1s;
  cursor: pointer;
  color: white;
  margin-top: 7px;
  font-size: 14px;
}
.button-submit:hover {
  background-color: rgb(3, 116, 182);
  color: rgb(255, 255, 255);
}

.successMessage {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(93, 180, 93);
  margin-top: 10px;
  color: white;
  font-weight: 600;
  padding: 5px;
}

.errorMessage {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(233, 62, 62);
  margin-top: 10px;
  color: white;
  font-weight: 600;
  padding: 5px;
}

.info-container {
  width: 45%;
  padding-left: 40px;
  border-left: 1px solid rgba(0, 0, 0, 0.192);
  display: flex;
  align-items: center;
}

.info-container .date {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.date-icon {
  margin-right: 10px;
}

.date-icon i {
  padding: 7px;
  font-size: 14px;
  border-radius: 50px;
  background-color: rgba(7, 72, 133, 0.658);
  color: white;
}

.date-icon .ubic {
  padding-left: 9px;
  padding-right: 9px;
}
.date-text {
  margin-left: 10px;
}

.date-title {
  font-weight: 500;
  color: black;
}

.date-text p {
  margin: 0;
  font-size: 15px;
}

.contact-redes {
  display: flex;
  margin-top: 35px;
}

.contact-redes i {
  margin-right: 30px;
  font-size: 20px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  transition: 1s;
}

.contact-redes i:hover {
  color: white;
  background-color: rgba(7, 72, 133, 0.658);
}

.contact-redes a {
  color: black;
}

form span {
  color: rgb(175, 6, 6);
}

@media screen and (max-width: 950px) {
  .container-contact {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 45px 0;
    align-items: center;
  }

  .form-container {
    width: 100%;
  }

  .info-container {
    width: 100%;
    padding: 40px 30px 0px 30px;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.192);
    margin-top: 40px;

  }
}

@media screen and (max-width: 420px) {
  .container-duo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-asunto-email {
    width: 100%;
  }
}
