/*-------------------EMPRESAS TOP----------------------*/
.bg-top-empresas {
  width: 100%;
  background-image: url(../../src/images/empresas/1.webp);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  margin-bottom: 30px;
}

.bg-top-empresas:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.651);
}

.title-view {
  text-align: center;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 200px;
}

/*-------------------BUSCADOR EMPRESAS ----------------------*/

.container-control {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.select-rubro {
  width: 320px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
}

.select-rubro select {
  border: 1px solid rgba(87, 87, 87, 0.616);
  padding: 4px;
  width: 100%;
}

.search {
  width: 320px;
  display: flex;
  justify-content: flex-start;
}

.search input {
  border: 1px solid rgba(87, 87, 87, 0.616);
  padding: 4px;
  width: 100%;
}

/*-------------------EMPRESAS  FILTRADAS---------------------*/

.container-empresas {
  width: 100%;
  background-color: rgb(236, 236, 236);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.empresa {
  width: 650px;
  padding: 20px 15px;
  margin-bottom: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 10px #00000029;
  cursor: pointer;
}

.empresa p {
  margin: 2px;
  color: rgba(0, 0, 0, 0.815);
}

.empresa span {
  font-weight: 600;
  color: black;
}

.empresa button {
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 15px;
  background-color: rgba(7, 72, 133, 0.658);
  transition: 1s;
  cursor: pointer;
  color: white;
  margin-top: 7px;
}
.empresa button:hover {
  background-color: rgb(3, 116, 182);
  color: rgb(255, 255, 255);
}

/*-------------------PAGINADOR----------------------*/

.paginator {
  display: flex;
  justify-content: center;
}
.page {
  padding: 10px 15px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  margin: 10px;
  font-weight: 700;
}

.page-selected {
  padding: 10px 15px;
  text-align: center;
  background-color: rgba(7, 72, 133, 0.658);
  margin: 10px;
  font-weight: 700;
  color: white;
}

/*-------------------EMPRESA TOP----------------------*/

.bg-top-empresa {
  width: 100%;
  background-image: url(../images/empresas/empresa-bg.webp);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.bg-top-empresa:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.651);
}
.title-empresa {
  text-align: center;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 140px;
}

/*-------------------DATOS EMPRESA----------------------*/
.container-empresa {
  width: 100%;
  display: flex;
  justify-content: center;
}

.about-empresa {
  width: 60%;
  padding: 20px;
  border-right: 1px solid rgba(177, 177, 177, 0.295);
}
.about-empresa span {
  font-weight: 600;

  color: black;
}

.info-empresa {
  width: 40%;
  padding: 20px;
}

.info-empresa p {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.815);
}

.info-empresa span {
  font-weight: 600;
  color: black;
}

.info-empresa button {
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 15px;
  background-color: rgba(7, 72, 133, 0.658);
  transition: 1s;
  cursor: pointer;
  color: white;
  margin-top: 7px;
  font-size: 14px;
}
.info-empresa button:hover {
  background-color: rgb(3, 116, 182);
  color: rgb(255, 255, 255);
}

.call {
  font-size: 12px;
  margin-right: 5px;
}
.button-back {
  padding: 3px 60px;
  margin: 20px;
  border-radius: 15px;
  background-color: rgba(235, 235, 235, 0.658);
  transition: 1s;
  cursor: pointer;
  color: rgb(3, 79, 151);
  font-size: 14px;
}

.button-back:hover {
  background-color: rgb(230, 230, 230);
  color: rgb(7, 112, 173);
}

@media screen and (max-width: 770px) {
  .container-empresa {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-empresa {
    width: 100%;
    padding: 20px;
    border-right: none;
    border-bottom: 1px solid rgba(177, 177, 177, 0.295);
  }

  .info-empresa {
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 660px) {
  .select-rubro {
    width: 50%;
  }

  .search {
    width: 50%;
  }

  .empresa {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .select-rubro {
    width: 100%;
    margin: 0px 0px 20px 0;
  }

  .search {
    width: 100%;
  }
  .container-control {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page {
    padding: 7px 12px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin: 5px;
    font-weight: 700;
  }

  .page-selected {
    padding: 7px 12px;
    text-align: center;
    background-color: rgba(7, 72, 133, 0.658);
    margin: 5px;
    font-weight: 700;
    color: white;
  }
}
