/*-------------------CCE----------------------*/

.bg-top-cce {
  width: 100%;
  background-image: url(../../src/images/CCE/bg-top.webp);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.bg-top-cce:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.651);
}

.title-view {
  text-align: center;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 200px;
}

.sub-title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 15px 0;
  color: rgb(7, 83, 146);
}

/*-------------------Frase top cce----------------------*/

.bg-text-top {
  margin-bottom: 15px;
  margin-top: 15px;
}

.text-top-cce {
  text-align: center;
  padding: 15px 5px 10px 5px;
}

.text-top-cce p {
  font-size: 17px;
  font-weight: 500;
  color: rgb(5, 42, 66);
}
/*-------------------VALORES----------------------*/
.subtitle-nosotros {
  text-align: center;
  margin: 20px 0;
}

.valores-cce {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.valor-cce {
  width: 200px;
  padding: 20px 15px;
  border-radius: 15px;
  margin: 0px 10px 10px 10px;
  background-color: rgba(235, 235, 235, 0.384);
  box-shadow: 0px 8px 10px #00000029;
  cursor: pointer;
}

.valor-cce:hover {
  background-color: #c5c6c748;
}

/*-------------------MISION----------------------*/
.container-mision {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.mision {
  width: 330px;
  padding: 20px 15px;
  margin: 10px;
  background-color: rgba(235, 235, 235, 0.384);
  box-shadow: 0px 8px 10px #00000029;
  cursor: pointer;
  transition: 2s;
}
.mision:hover {
  background-color: #cde8f863;
}

.mision:hover span {
  color: #1891d8;
}

.mision span {
  font-weight: 600;
  color: #225486;
  transition: 2s;
}

/*-------------------AREAS----------------------*/

.cce-tabs {
  margin-top: 20px;
}

.cce-tabs .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .cce-tabs .nav-link {
    transition: none;
  }
}
.cce-tabs .nav-link:hover,
.nav-link:focus {
  color: #ffffff;
}
.cce-tabs .nav-link.disabled {
  color: white;
  pointer-events: none;
  cursor: default;
}

.cce-tabs .nav-tabs {
  border-bottom: 2px solid #146cc4;
}
.cce-tabs .nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  color: black;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.cce-tabs .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #2170be36 #2170be36 #2170be36;
  background-color: #2170be36;
  isolation: isolate;
}
.cce-tabs .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.cce-tabs .nav-tabs .nav-link.active,
.cce-tabs .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #2170be;
  border-color: #2170be #2170be #2170be;
}
.cce-tabs .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cce-tabs .nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.cce-tabs .nav-pills .nav-link.active,
.cce-tabs .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.cce-tabs .nav-fill > .nav-link,
.cce-tabs .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.cce-tabs .nav-justified > .nav-link,
.cce-tabs .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.cce-tabs .nav-fill .nav-item .nav-link,
.cce-tabs .nav-justified .nav-item .nav-link {
  width: 100%;
}

.cce-tabs .tab-content > .tab-pane {
  display: none;
}
.cce-tabs .tab-content > .active {
  display: block;
}

.data-area {
  width: 100%;
  background-color: rgba(235, 235, 235, 0.384);
  margin: 20px 15px;
  box-shadow: 0px 8px 10px #00000029;
  cursor: pointer;
}

.data-area:hover {
  background-color: #c5c6c748;
}

.data-area-box {
  padding: 20px 15px;
}

.data-area-box h4 {
  color: #1a5a99;
}

.container-team {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.member {
  width: 210px;
  position: relative;
  cursor: pointer;
}

.member img {
  outline: 6px solid #1a5a99d8;
  outline-offset: -6px;
}

.member-hover {
  position: absolute;
  top: 0;
  font-size: 15px;
  background: #1a5a99d8;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: transform 0.5s;
}

.member-hover p {
  color: white;
  font-weight: 600;
}

.member:hover .member-hover {
  transform: scale(1);
}

/*-------------------VALOR AL SOCIO----------------------*/

.custom-institituion-values-item {
  height: 230px;
}

.custom-institituion-values-item p {
  padding-top: 30px;
  margin: auto;
}

.services {
  background: rgba(184, 184, 184, 0.212);
  padding: 40px 0;
}

.services .single-service {
  position: relative;
  text-align: center;
  background: #fff;
  padding: 55px 10px;
  margin: 5px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.services .single-service:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background: #1676bb;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.services .single-service:hover:before {
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.services .single-service .icon {
  font-size: 40px;
  position: relative;
  text-align: center;
  color: #1b6ebc;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  display: inline-block;
}
.services .single-service:hover .front {
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}
.services .single-service .icon.back {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  opacity: 0;
  transform: scale(0);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
}
.services .single-service:hover .icon.back {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.services .single-service h4 {
  font-size: 18px;
  margin: 15px 0;
}
.services .single-service h4 a {
  color: #282828;
  font-weight: 700;
}
.services .single-service:hover h4 a {
  color: #2f7cd4;
}
.services .single-service .btn {
  color: #666;
  background: transparent;
  text-transform: capitalize;
  box-shadow: none;
  padding: 0;
  margin-top: 15px;
}
.services .single-service .btn:hover {
  color: #1676bb;
}
.services .single-service .btn i {
  margin-left: 5px;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  display: inline-block;
  width: 0px;
}
.services .owl-nav {
  margin-top: 40px;
  text-align: center;
}
.services .owl-nav div {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  font-size: 22px;
  background: #1676bb;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.services .owl-nav div:hover {
  background: #222534;
  color: #fff;
}
.services .owl-nav div:last-child {
  margin: 0;
}

/*-------------------SLIDER----------------------*/

.swiper-container {
  width: 100%;
  height: 70%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/*-------------------COMISION DIRECTICA----------------------*/

.member-slider {
  width: 100%;
  height: 130px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-bottom: 40px;
}

.data-member {
  position: absolute;
  top: 10%;
  width: 100%;
  background: #1a5a99d8;
  padding: 3%;
}

.member-status {
  margin: 0;
  padding: 0;
  color: rgb(172, 201, 255);
  font-weight: 700;
}

.member-name {
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 10px;
}

.container-members {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.members-mini {
  width: 70%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.members-gerente {
  width: 40%;
  text-align: center;
}

/*-------------------PRESIDENTES HISTORICOS----------------------*/

.container-presidentes {
  display: flex;
  width: 95%;
  justify-content: center;
  flex-wrap: wrap;
}

.presidente-img {
  width: 100%;
}
.data-presidente {
  position: absolute;
  top: 65%;
  width: 100%;
  background: #050f1a;
  text-align: center;
}

.presidente-name {
  margin: 0;
  padding: 0;
  color: rgb(172, 201, 255);
  font-weight: 700;
  font-size: 14px;
}

.presidente-periodo {
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 600;
  font-size: 10px;
  padding-bottom: 10px;
}
.presidente {
  width: 170px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin: 5px;
  transition: 1s;
}

.faltante-presidente {
  width: 170px;
  margin: 5px;
}

.presidente:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1399px) {
  .container-presidentes {
    width: 100%;
  }
}

@media screen and (max-width: 1198px) {
  .faltante-presidente {
    width: 340px;
    margin: 5px 10px;
  }
}
@media screen and (max-width: 975px) {
  .mision {
    width: 95%;
    height: 130px;
    display: flex;
    align-items: center;
  }

  .faltante-presidente {
    width: 170px;
    margin: 5px;
  }

  .members-mini {
    width: 100%;
  }

  .data-member {
    position: absolute;
    top: 10%;
  }
}

@media screen and (max-width: 767px) {
  .valor-cce {
    width: 230px;
  }

  .members-mini {
    width: 100%;
  }

  .data-member {
    position: absolute;
    top: 10%;
  }
}

@media screen and (max-width: 640px) {
  .data-member {
    position: absolute;
    top: 10%;
  }
}

@media screen and (max-width: 600px) {
  .members-mini {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .mision {
    height: 190px;
  }

  .valor-cce {
    width: 95%;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 384px) {
  .data-presidente {
    top: 62%;
  }

  .presidente-name {
    font-size: 12px;
  }

  .presidente-periodo {
    font-size: 10px;
  }
  .presidente {
    width: 48%;
    margin: 1px;
  }
}
