/*-------------------HOME TOP----------------------*/

.bg-top {
  background-image: url(../../src/images/home/background.webp);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 550px;
}

.bg-top:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.404);
}

.home-top-container {
  width: 620px;
  padding: 60px 0;
  color: white;
}

.title-top {
  font-size: 50px;
  font-weight: 700;
}

.text-top {
  color: #fff;
  position: relative;
}

.button-top {
  padding: 10px 20px;
  border-radius: 25px;
  background-color: rgb(2, 102, 196);
  transition: 1s;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 40px;
}
.button-top:hover {
  background-color: white;
  color: rgb(2, 102, 196);
}

.button-top-cce {
  padding: 10px 45px;
  border-radius: 25px;
  background-color: white;
  color: rgb(2, 102, 196);
  transition: 1s;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 40px;
}
.button-top-cce:hover {
  background-color: rgb(2, 102, 196);
  color: white;
}

.link-button {
  color: white;
}

.text-effect {
  font-size: 30px;
  color: #fff;
  position: relative;
  font-weight: 600;
}

/*-------------------SPONSORS----------------------*/

.bg-empresas-slider {
  background-color: #095492e1;
  padding: 30px;
}
.container-sponsors {
  margin-top: 20px;
}

/*-------------------FRASE HOME----------------------*/

.quote-home {
  margin-top: 20px;
  padding: 15px 0;
  justify-content: center;
  text-align: center;
  display: flex;
}

.quote-home p {
  width: 100%;
  color: #001b4d;
  font-size: 23px;
  font-weight: 500;
}

/*-------------------ESTADISTICAS----------------------*/
.CircularProgressbar {
  width: 120px;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: rgb(2, 102, 196);
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: rgb(2, 102, 196);
  font-size: 20px;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.estadistica {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #1676bb;
  border: #001b4d 1px;
  border-radius: 5px;
}
.estadistica p{
  color: #1676bb;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 50px;
  font-weight: 900;
}
.estadistica h4 {
  text-align: center;
  color: #000;
  font-size: 15px;
  font-weight: 700;
}

.container-estadistica {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*-------------------ENTIDADES ASOCIADAS----------------------*/

.bg-gray {
  background-color: whitesmoke;
  padding: 60px 0;
}

.entidad {
  width: 250px;
  height: 100px;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  background: white;
  margin-bottom: 10px;
}

.entidad:hover {
  border-bottom: 3px solid rgba(3, 69, 209, 0.363);
}

.entidad-img {
  width: 160px;
}

.slider-entidades {
  width: 100%;
  height: 120px;
}

/*-------------------NOTICIAS----------------------*/

.bg-notices {
  background-image: url(../../src/images/home/fondo-noticias.webp);
  background-size: cover;
  position: relative;
  padding: 1px 0;
}

.img-notices {
  width: 500px;
}

.facebook-container {
  width: 500px;
}

.notices {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notices-container {
  width: 50%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-------------------NUESTRAS AREAS----------------------*/
.services.single {
  background: #fff;
  padding: 0;
  margin: 30px;
}
.services.single .single-service {
  text-align: left;
  padding: 0;
  box-shadow: none;
  margin: 0;
}
.services.single .single-service:before {
  display: none;
}

.services.single .single-feature {
  text-align: left;
  position: relative;
  padding-left: 50px;
  margin-top: 25px;
}
.services.single .single-feature i {
  left: 0;
  font-size: 30px;
  border-right: 1px solid #e6e6e6;
  padding-right: 5px;
  padding-bottom: 5px;
  position: absolute;
  color: #1676bb;
}
.services.single .single-feature h4 {
  margin: 0 0 5px;
  font-size: 22px;
  font-weight: 600;
}
.services.single .single-feature p {
  line-height: 20px;
  font-size: 18px;
}

@media screen and (max-width: 1200px) {
  .container-img-notices {
    display: none;
  }
  .notices-container {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .bg-top {
    width: 100%;
    height: 620px;
  }

  .home-top-container {
    width: 100%;
    padding: 40px 15px;
  }

  .title-top {
    font-size: 35px;
    font-weight: 700;
  }

  .text-effect {
    margin-bottom: 15px;
    font-size: 25px;
    color: #fff;
    position: relative;
    font-weight: 600;
  }

  .container-effect {
    height: 75px;
  }
}

@media screen and (max-width: 600px) {
  .notices-container {
    width: 100%;
  }
}
