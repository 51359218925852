     /*-------------------GENERAL TEMPLATE----------------------*/

html {
  font-family: "Raleway", sans-serif;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
html,
body {
  height: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
}

p {
  color: #666;
  margin: 0;
}
a,
button,
img {
  position: relative;
  color: inherit;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  text-decoration: none;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
  display: block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
button,
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  border: none;
  outline: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
select:focus,
select:active {
  box-shadow: 0;
  border: 0;
  outline: 0;
}
ul,
ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}
input,
input:focus,
input:active {
  border: none;
  outline: none;
  font-family: "Raleway", sans-serif;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,

     /*-------------------HR FRASE----------------------*/
hr {
  border: none;
  height: 1px;
  background-image: linear-gradient(to right, #1891d8, #031b50, #1891d8);
}
hr::after {
  content: "§";
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%) rotate(60deg);
  transform-origin: 50% 50%;
  padding: 1rem;
  background-color: white;
}

     /*-------------------SECTION - TITLE SECTION ----------------------*/

.section-title {
  margin-bottom: 30px;
  text-align: center;
  text-align: center;
}
.section-title h2 {
  font-size: 30px;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  display: inline-block;
  text-transform: uppercase;
}
.section-title h2 span {
  font-weight: 600;
  color: #1676bb;
  font-size: 15px;
  display: block;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.section-title h2::before {
  position: absolute;
  content: "";
  z-index: 1;
  width: 120px;
  height: 2px;
  bottom: 0;
  left: 50%;
  margin-left: -60px;
  background: #1676bb;
}
.section-title h2::after {
  position: absolute;
  content: "";
  z-index: 1;
  width: 60px;
  height: 4px;
  bottom: -1px;
  left: 50%;
  margin-left: -30px;
  background: #1676bb;
  border-radius: 3px;
}
.section-title p {
  font-size: 15px;
}

     /*-------------------NAV----------------------*/
.contenedor-nav {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.contenedor-logo {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.logo-menu {
  width: 64px;
}
.contenedor-menu {
  width: 30%;
  justify-content: flex-end;
}

#responsive-navbar-nav {
  justify-content: flex-end;
}

#collasible-nav-dropdown {
  color: #050f1a;
}

#collasible-nav-dropdown:hover {
  color: #28639e !important;
}

.menu-link {
  color: #0a0b0c !important;
}

.menu-link:hover {
  color: #28639e !important;
}

.menu-drop-link:hover {
  background-color: #84b7ec !important;
  font-weight: 600;
}

.flex-center {
  display: flex;
  justify-content: center;
}

     /*-------------------FOOTER----------------------*/

footer {
  background-color: #262631;
}

.container-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 0;
}
.social-footer {
  color: white;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redes-footer {
  display: flex;
  color: white;
}

.social-footer ul {
  margin-bottom: 5px !important;
}

.redes-footer li {
  color: white;
  font-size: 25px;
  text-decoration: none;
  margin-right: 10px;
  padding: 0px 7px;
}
.link-social {
  color: white;
  text-decoration: none;
}

.contact-footer {
  color: white;
  font-size: 13px;
  font-weight: 400;
  width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.social-footer h5,
.contact-footer h5 {
  color: #aad5ff;
}

.contact-footer a {
  color: white;
  text-decoration: none;
  font-weight: 400;
}

.contact-list {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contact-list li i {
  margin-right: 10px;
}

.logo-footer {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-friscop img {
  width: 80px;
}
/* .logo-footer img {
  width: 180px;
} */

.copy-footer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(20, 20, 20, 0.582);
}

.copy-footer p {
  color: white;
}

.container-section {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 570px) {
  .contenedor-nav {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
  }

  .contenedor-logo {
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }

  .contenedor-menu {
    width: 50%;
    justify-content: flex-start;
  }

  .logo-footer {
    display: none;
  }
}
